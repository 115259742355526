import React, { useEffect, useRef, useState } from "react";

const slideArrow = (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.82495 13.3167L8.64162 9.5L4.82495 5.675L5.99995 4.5L11 9.5L5.99995 14.5L4.82495 13.3167Z"
      fill="#262626"
    />
  </svg>
);

const showMore = (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9831 4.00783V0H9.01637V4.00783H5.00854V10.4892C5.00854 12.9581 7.01716 14.9667 9.48604 14.9667H10.5072C11.4925 16.2821 12.9001 17.2638 14.5271 17.7088V21.0411H12.5232V23.045H11.0203V29.0568H9.01637V32H23.9831V29.0568H21.9792V23.045H20.4763V21.0411H18.4723V17.7088C20.0993 17.2638 21.507 16.2821 22.4923 14.9667H23.5134C25.9823 14.9667 27.9909 12.9581 27.9909 10.4892V4.00783H23.9831ZM21.0399 29.0568H19.0046V29.9961H23.0438V31.0607H9.95571V29.9961H18.0027V29.0568H11.9596V23.9843H21.0399V29.0568ZM19.5369 23.045H13.4626V21.9804H19.5369V23.045ZM17.533 21.0411H15.4665V17.9015C15.8043 17.9484 16.1492 17.9726 16.4997 17.9726C16.8502 17.9726 17.1952 17.9484 17.533 17.9015V21.0411ZM16.4997 17.0333C12.8913 17.0333 9.95571 14.0976 9.95571 10.4892V8.9863H9.01637V10.4892C9.01637 11.7682 9.33894 12.9733 9.90692 14.0274H9.48604C7.5351 14.0274 5.94788 12.4402 5.94788 10.4892V4.94716H9.01637V7.98434H9.95571V2.94325H18.5037V2.00391H9.95571V0.939335H23.0438V2.00391H19.5056V2.94325H23.0438V10.4892C23.0438 14.0976 20.1082 17.0333 16.4997 17.0333ZM27.0516 10.4892C27.0516 12.4402 25.4644 14.0274 23.5134 14.0274H23.0926C23.6605 12.9733 23.9831 11.7682 23.9831 10.4892V4.94716H27.0516V10.4892Z"
      fill="#262626"
    />
    <path
      d="M13.9949 26.0508H14.9968V26.9901H13.9949V26.0508Z"
      fill="#262626"
    />
    <path
      d="M15.9988 26.0508H17.0007V26.9901H15.9988V26.0508Z"
      fill="#262626"
    />
    <path
      d="M18.0027 26.0508H19.0046V26.9901H18.0027V26.0508Z"
      fill="#262626"
    />
  </svg>
);

const EventCategories = ({ categories, current }) => {
  const scrollRef = useRef();
  const [currentScroll, setCurrentScroll] = useState(0);
  const [activateScroll, setActivateScroll] = useState(false);
  const category = current
    ? categories.find((ctgy) => ctgy.id == current.id)
    : false;

  const scrollToPosition = () => {
    if (!scrollRef.current) return;

    const totalScroll =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

    if (currentScroll < totalScroll) {
      const nextScroll = currentScroll + 180;
      scrollRef.current.scrollTo({
        left: nextScroll,
        behavior: "smooth",
      });

      setCurrentScroll(nextScroll);
    } else {
      scrollRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
      setCurrentScroll(0);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      setActivateScroll(
        scrollRef.current.scrollWidth > scrollRef.current.offsetWidth
      );
    }
  }, []);

  return (
    <>
      <div
        className="sport-events__categories"
        style={{ paddingRight: current ? 180 : 64 }}
        ref={scrollRef}
      >
        {category && (
          <a
            href={category.url}
            data-current="true"
            className="sport-events__categories__item"
          >
            <div className="sport-events__categories__item__icon">
              <img src={category.img} />
            </div>

            {category.name}
          </a>
        )}

        {categories.map((ctgy) => {
          if (category && ctgy.id == category.id) return;

          return (
            <a
              href={ctgy.url}
              className="sport-events__categories__item"
            >
              <div className="sport-events__categories__item__icon">
                <img src={ctgy.img} />
              </div>

              {ctgy.name}
            </a>
          );
        })}
      </div>

      <div className="event-categories__controls">
        {activateScroll && (
          <button
            type="button"
            className="sport-events__categories__item"
            onClick={scrollToPosition}
          >
            {slideArrow}
          </button>
        )}
        {category && (
          <a href={"/eventos"} className="sport-events__categories__item">
            {showMore}
            Ver todos
          </a>
        )}
      </div>
    </>
  );
};

export default EventCategories;
